export default {
    allAppList: [
        {
            key: 'ht_dashboard',
            component: 'DemoDrug',
            icon: 'icon-jellyfish-glyph',
            title: '数据驾驶舱',
            iconColor: '#fff',
            iconBgColor: '#db5048',
            width: 900,
            height: 600,
            innerLink: true,
            keepInDock: true,
            // url: 'http://localhost:18081/login'
            url: `https://dashboard.haitao.studio:55543`
        },
        {
            key: 'ht_workbench',
            component: 'DemoWorkBench',
            icon: 'icon-Send',
            title: '情报工作台',
            iconColor: '#fff',
            iconBgColor: '#518dff',
            width: 900,
            height: 600,
            innerLink: true,
            keepInDock: true,
            // url: 'http://localhost:18082/login'
            url: `https://workbench.haitao.studio:55543`
        },
        {
            key: 'ht_ai',
            component: 'DemoAi',
            icon: 'icon-24gf-bubbleDots',
            title: 'AI禁毒专家-小T',
            iconColor: '#fff',
            iconBgColor: '#00ac97',
            width: 900,
            height: 600,
            innerLink: true,
            keepInDock: true,
            // url: 'http://localhost:18083/login'
            url: `https://ai.haitao.studio:55543`
        },
        {
            key: 'system_setting',
            component: 'SystemSetting',
            icon: 'icon-setting-fill',
            title: '应用设置',
            iconColor: '#333',
            iconBgColor: '#d4dbef',
            width: 800,
            height: 600,
            hideInDesktop: true,
            keepInDock: true
        }
    ]
};
