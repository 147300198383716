import { createApp } from 'vue';
import { createStore } from 'vuex';

import SDOS from './SDOS';
let sdOS = createApp(SDOS);

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
sdOS.use(ElementPlus, {
    locale: zhCn
});

import '@/asset/css/app.css';
import '@/asset/css/animation.css';

import config from './config';
sdOS.config.globalProperties.config = config;

import tool from './utils/tool';
sdOS.config.globalProperties.tool = tool;

import AppStore from './store/App';
const store = createStore(AppStore);
sdOS.use(store);

window.sdOS = sdOS;
sdOS.mount('#app');
