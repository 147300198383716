<template>
    <div class="login">
        <div class="head" :style="{ backgroundImage: 'url(' + headImage + ')' }"></div>
        <div class="message">
            {{ haveSavedUserName ? user_name : '欢迎使用 HT-OS' }}
        </div>
        <div class="form">
            <div class="item" v-if="!haveSavedUserName" :class="isUserNameError ? 'error' : ''">
                <input class="account" placeholder="请输入用户名" type="email" v-model="user_name" />
            </div>
            <div class="item" :class="isUserPasswordError ? 'error' : ''">
                <input class="password" placeholder="请输入密码" type="password" v-model="user_password" :class="user_password ? 'password-in' : ''" />
                <i class="login-button iconfont icon-Send" :class="user_password ? 'click-enable' : ''" @click="login"></i>
            </div>
            <div class="loginMsg">
                <transition name="fade">
                    <span v-show="isLoginMsg">{{ loginMsg }}</span>
                </transition>
            </div>

            <div v-if="isServerSetting">
                <el-divider>服务器地址设置</el-divider>

                <div class="item" style="width: 350px">
                    <input class="password" style="width: 255px" placeholder="请输入服务器地址" type="text" v-model="serverUrl" />
                    <i class="login-button click-enable iconfont icon-queren" @click="setWebUrl"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
::-webkit-input-placeholder {
    color: #fff;
}

::-moz-placeholder {
    color: #fff;
}

:-ms-input-placeholder {
    color: #fff;
}
.login {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: -100px;
    z-index: 99999;
    backdrop-filter: blur(100px);
    .head {
        background-size: 40% auto;
        background-position: center center;
        height: 150px;
        width: 150px;
        border-radius: 100%;
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
        margin-top: -50px;
    }
    .message {
        margin-top: 20px;
        font-size: 20px;
        text-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
        color: #eee;
        margin-bottom: 50px;
    }
    .form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .error {
            animation: loginErrorAnimation 0.2s ease 3;
        }
        .item {
            vertical-align: middle;
            position: relative;
            width: 250px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            input {
                color: white;
                outline: none;
                border: none;
                margin: 5px;
                font-size: 16px;
                background-color: rgba(255, 255, 255, 0.3);
                padding: 8px 24px;
                border-radius: 20px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
                width: 100%;
            }

            .password-in {
                width: 155px;
            }

            .password {
                transition: width 0.3s;
            }

            .login-button {
                position: absolute;
                top: 5px;
                right: -50px;
                transition: right 0.3s;
            }

            .iconfont {
                vertical-align: middle;
                display: inline-block;
                background-color: rgba(255, 255, 255, 0.3);
                font-size: 18px;
                border-radius: 100%;
                width: 36px;
                height: 36px;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            }
            .iconfont:hover {
                background-color: rgba(255, 255, 255, 0.5);
            }
            .click-enable {
                right: 0;
            }
        }
    }
    .loginMsg {
        height: 24px;
        color: #fc9b2c;
    }
}
</style>
<script>
import request from '@/utils/request.js';
import { ipc, isEE } from '@/utils/ipcRenderer.js';

export default {
    data() {
        return {
            headImage: require('@/asset/img/bg.png'),
            user_name: '',
            user_password: '',
            haveSavedUserName: false,
            isUserNameError: false,
            isUserPasswordError: false,
            isLoginMsg: false,
            loginMsg: '',
            // 登录服务器地址
            isServerSetting: false,
            serverUrl: 'https://api.haitao.studio:55543',
            serverFullUrl: '',
            serverFullAlive: '',
            // 定义通信频道，即路由
            ipcApiRoute: {
                getWebServer: 'controller.server.getWebServer',
                setWebServer: 'controller.server.setWebServer'
            },
            refreshTokenTimeout: null
        };
    },
    mounted() {
        this.stopRefreshTokenTimer();
        // this.haveSavedUserName = false;
        // let user_name = localStorage.getItem('user_name') || false;
        // if (user_name) {
        //     this.user_name = user_name;
        //     this.haveSavedUserName = true;
        // }

        // electron
        if (isEE) {
            ipc.removeAllListeners(this.ipcApiRoute.getWebServer);
            ipc.removeAllListeners(this.ipcApiRoute.setWebServer);

            // 获取服务地址
            ipc.on(this.ipcApiRoute.getWebServer, (event, result) => {
                if (result) {
                    this.serverUrl = result;
                    this.serverFullUrl = result + '/api/v1/user/token';
                    this.serverFullAlive = result + '/api/v1/user/alive';
                }
            });

            ipc.send(this.ipcApiRoute.getWebServer, '');

            // 设置服务地址
            ipc.on(this.ipcApiRoute.setWebServer, (event, result) => {
                if (result) {
                    this.isServerSetting = false;
                    this.serverFullUrl = result + '/api/v1/user/token';
                    this.serverFullAlive = result + '/api/v1/user/alive';
                }
            });
        } else {
            // this.serverFullUrl = 'http://192.168.31.207:17000' + '/api/v1/user/token';
            // this.serverFullAlive = 'http://192.168.31.207:17000' + '/api/v1/user/alive';
            this.serverFullUrl = '/api/v1/user/token';
            this.serverFullAlive = '/api/v1/user/alive';
        }

        // 注册快捷键打开配置窗口 及回车
        document.addEventListener('keydown', this.handleKeyDown);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);

        if (isEE) {
            ipc.removeAllListeners(this.ipcApiRoute.getWebServer);
            ipc.removeAllListeners(this.ipcApiRoute.setWebServer);
        }
    },
    methods: {
        // guest() {
        //     localStorage.setItem('user_name', 'Guest');
        //     this.$emit('logined');
        // },
        login() {
            if (!this.user_name) {
                this.isUserNameError = true;
                setTimeout(() => {
                    this.isUserNameError = false;
                }, 1000);
                return;
            }
            if (!this.user_password) {
                this.isUserPasswordError = true;
                setTimeout(() => {
                    this.isUserPasswordError = false;
                }, 1000);
                return;
            }

            const formData = new FormData();
            formData.append('username', this.user_name);
            formData.append('password', this.user_password);

            const data = {
                url: this.serverFullUrl,
                method: 'post',
                data: formData
            };
            request(data)
                .then((res) => {
                    if (res.status === 200 && res.data) {
                        if (res.data?.access_token) {
                            this.tool.saveAccessToken(res.data?.access_token);
                            localStorage.setItem('user_name', this.user_name);
                            this.$emit('logined');

                            // 设置定时器请求刷新
                            this.startRefreshTokenTimer();
                        } else {
                            this.isLoginMsg = true;
                            this.loginMsg = res.data.msg;

                            // 登录错误动画
                            this.isUserNameError = true;
                            this.isUserPasswordError = true;
                            setTimeout(() => {
                                this.isUserNameError = false;
                                this.isUserPasswordError = false;
                            }, 1000);
                        }
                    } else {
                        this.isLoginMsg = true;
                        this.loginMsg = '服务器错误';
                    }
                })
                .catch((e) => {
                    this.loginMsg = '服务器连接错误，请检查';
                    this.isLoginMsg = true;
                    console.log(e);
                });
        },
        // 设置登录服务器地址
        setWebUrl() {
            if (this.serverUrl && isEE) {
                ipc.send(this.ipcApiRoute.setWebServer, this.serverUrl);
            }
        },
        handleKeyDown(event) {
            // 处理键盘事件
            if (event.key === 'l' && (event.ctrlKey || event.metaKey)) {
                this.isServerSetting = true;
            }

            if (event.keyCode === 13) {
                this.login();
            }
        },
        // 自动刷新token
        startRefreshTokenTimer() {
            // 2小时 刷新一次
            const timeout = 2 * 60 * 60 * 1000;

            this.refreshTokenTimeout = setTimeout(this.toRefreshToken, timeout);

            localStorage.setItem('timeId', this.refreshTokenTimeout);
        },
        stopRefreshTokenTimer() {
            clearTimeout(localStorage.getItem('timeId'));
        },
        toRefreshToken() {
            const data = {
                url: this.serverFullAlive,
                method: 'post'
            };
            request(data);

            this.startRefreshTokenTimer();
        }
    }
};
</script>
