import axios from 'axios';
axios.defaults.baseURL = '/apis';

// 设置超时时间
axios.defaults.timeout = 3000;

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config; //赋值完后把config返回回去
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截
axios.interceptors.response.use(
    (response) => {
        if (response.data.status || response.data.status === 0) {
            return response;
        }

        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axios;
