<template>
  <div class="widget">
    <span>我是提示框</span>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.widget {
  // position: relative;
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 0;
  width: 200px;
  height: 100%;
}
</style>
